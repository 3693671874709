import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { BluebenxPortfolio } from '_templates'
import { AppContext } from '_context'
import variables from '_config/css-variables'

const BluebenxCaseStudy = ({ data }) => {
  const content = data.contentfulBluebenxCaseStudy
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  useEffect(() => {
    setNavbarColor(variables['color-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  const noCurbsMobileScreens = [
    {
      id: 'noCurbsMobileScreenBuy',
      image: content.noCurbsMobileScreenBuy.fluid,
      alt: content.noCurbsMobileScreenBuy.description,
    },
    {
      id: 'noCurbsMobileScreenList',
      image: content.noCurbsMobileScreenList.fluid,
      alt: content.noCurbsMobileScreenList.description,
    },
    {
      id: 'noCurbsMobileScreenPortfolio',
      image: content.noCurbsMobileScreenPortfolio.fluid,
      alt: content.noCurbsMobileScreenPortfolio.description,
    },
  ]

  return (
    <BluebenxPortfolio
      navbarColorOverride={variables['color-primary']}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageBackgroundImage={content.pageBackgroundImage.fluid}
      bluebenxLogo={content.bluebenxLogo.fluid}
      bluebenxLogoAlt={content.bluebenxLogo.description}
      heroTitle={content.heroTitle}
      heroDescription={content.heroDescription.internal.content}
      heroBackground={content.heroBackground.file.url}
      heroMobileScreen={content.heroMobileScreen.fluid.srcWebp}
      heroMobileScreenAlt={content.heroMobileScreen.description}
      visionTitle={content.visionTitle}
      visionDescription={content.visionDescription.internal.content}
      visionBackground={content.visionBackground.file.url}
      visionImage={content.visionImage.fluid.srcWebp}
      visionImageAlt={content.visionImage.description}
      middleText={content.middleText.internal.content}
      middleBackground={content.middleBackground.fluid.srcWebp}
      walletTitle={content.walletTitle}
      walletDescription={content.walletDescription.internal.content}
      walletMobileScreen={content.walletMobileScreen.fluid.srcWebp}
      walletMobileScreenAlt={content.walletMobileScreen.description}
      noCurbsTitle={content.noCurbsTitle}
      noCurbsDescription={content.noCurbsDescription.internal.content}
      noCurbsMobileScreens={noCurbsMobileScreens}
      noCurbsBackground={content.noCurbsBackground.file.url}
      noCurbsBackgroundAlt={content.noCurbsBackground.description}
      tokensTitle={content.tokensTitle}
      tokensDescription={content.tokensDescription.internal.content}
      stellarBackground={content.stellarBackground.fluid.srcWebp}
      stellarBackgroundAlt={content.stellarBackground.description}
      resultsImage={content.resultsImage.file.url}
      resultsImageAlt={content.resultsImage.description}
      resultsTitle={content.resultsTitle}
      resultsText1A={content.resultsText1A.internal.content}
      resultsText1B={content.resultsText1B.internal.content}
      resultsText1C={content.resultsText1C.internal.content}
      resultsText2A={content.resultsText2A.internal.content}
      resultsText2B={content.resultsText2B.internal.content}
      resultsText2C={content.resultsText2C.internal.content}
      proudCases={content.proudCases}
    />
  )
}

export const query = graphql`
  query contentfulBluebenxCaseStudy($locale: String) {
    contentfulBluebenxCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageBackgroundImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bluebenxLogo {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroTitle
      heroDescription {
        internal {
          content
        }
      }
      heroBackground {
        description
        file {
          url
        }
      }
      heroMobileScreen {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      visionTitle
      visionDescription {
        internal {
          content
        }
      }
      visionBackground {
        description
        file {
          url
        }
      }
      visionImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      middleText {
        internal {
          content
        }
      }
      middleBackground {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      walletTitle
      walletDescription {
        internal {
          content
        }
      }
      walletMobileScreen {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      noCurbsTitle
      noCurbsDescription {
        internal {
          content
        }
      }
      noCurbsMobileScreenBuy {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      noCurbsMobileScreenList {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      noCurbsMobileScreenPortfolio {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      noCurbsBackground {
        description
        file {
          url
        }
      }
      tokensTitle
      tokensDescription {
        internal {
          content
        }
      }
      stellarBackground {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      resultsImage {
        description
        file {
          url
        }
      }
      resultsTitle
      resultsText1A {
        internal {
          content
        }
      }
      resultsText1B {
        internal {
          content
        }
      }
      resultsText1C {
        internal {
          content
        }
      }
      resultsText2A {
        internal {
          content
        }
      }
      resultsText2B {
        internal {
          content
        }
      }
      resultsText2C {
        internal {
          content
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

BluebenxCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default BluebenxCaseStudy
